import {
  DEXSCREENER,
  DEXTOOLS,
  TELEGRAM,
  TWITTER,
  UNISWAP,
} from "../urls";
import { DexscreenerIcon } from "./icons/dexscreener-icon";
import { DextoolsIcon } from "./icons/dextools-icon";
import { TgIcon } from "./icons/tg-icon";
import { XIcon } from "./icons/x-icon";
import {UNIcon} from "./icons/uniswap-icon"

const buttonClass =
  " select-none font-bold cursor-pointer rounded-full flex flex-row content-center items-center transform transition duration-500 hover:scale-105 z-50";

const iconClass = "w-12 h-12 fill-black";

const Checker = (props: any) => {
  return <>{props.val.length !== 0 && props.children}</>;
};

export const Links = (props: any) => {
  return (
    <div
      className={
        "w-full z-50 flex justify-center gap-10 gap-y-4 lg:gap-24 flex-row flex-wrap max-w-screen-2xl " +
        props.customClass
      }
    >
       <Checker val={UNISWAP}>
        <a
          target="_blank"
          rel="noreferrer"
          href={UNISWAP}
          className={buttonClass}
        >
          <UNIcon iconClass={iconClass} fillColor="#fc0278" />
        </a>
      </Checker>
      <Checker val={TWITTER}>
        <a
          target="_blank"
          rel="noreferrer"
          href={TWITTER}
          className={buttonClass}
        >
          <XIcon iconClass={iconClass} fillColor="#fc0278" />
        </a>
      </Checker>
      <Checker val={TELEGRAM}>
        <a
          target="_blank"
          rel="noreferrer"
          href={TELEGRAM}
          className={buttonClass}
        >
          <TgIcon iconClass={iconClass} fillColor="#fc0278" />
        </a>
      </Checker>
    
      <Checker val={DEXSCREENER}>
        <a
          target="_blank"
          rel="noreferrer"
          href={DEXSCREENER}
          className={buttonClass}
        >
          <DexscreenerIcon iconClass={iconClass} fillColor="#fc0278"  />
        </a>
      </Checker>
      <Checker val={DEXTOOLS}>
        <a
          target="_blank"
          rel="noreferrer"
          href={DEXTOOLS}
          className={buttonClass}
        >
          <DextoolsIcon iconClass={iconClass} fillColor="#fc0278"  />
        </a>
      </Checker>
    </div>
  );
};
