export const CA = "";
export const TELEGRAM = "https://t.me/latexpepe";
export const TWITTER = "https://x.com/latexpepe_eth";
export const BUY_LINK =
  "https://app.uniswap.org/swap";
export const DEXSCREENER =
  "https://dexscreener.com/ethereum";
export const DEXTOOLS =
  "https://www.dextools.io/app/en/ethereum/pair-explorer/";
export const UNISWAP =
  "https://app.uniswap.org/swap";
export const TELEGRAM_DEV = "https://t.me/latexpepe";
export const TWITTER_DEV = "https://x.com/latexpepe_eth";
