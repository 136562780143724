import { MovingImg } from "../../common/moving-img";

export const Block2 = () => {
  return (
    <div className="min-h-screen w-full flex relative justify-center items-center bg-cover bg-center" >
      <div className="flex flex-col w-full h-full max-w-screen-2xl space-y-24 py-12">
        <div className="flex flex-col space-y-12 lg:flex-row justify-between items-center px-8">
          <MovingImg
            customClassWrapper={"relative"}
            tz={3}
            tx={-18}
            ty={15}
            customClassImg={"w-full rounded-xl h-80 "}
            imgPath={"./block2/pl5.png"}
          />
          <MovingImg
            customClassWrapper={"relative"}
            tz={3}
            tx={18}
            ty={-5}
            customClassImg={"rounded-xl h-80 "}
            imgPath={"./block2/pl6.png"}
          />
        </div>
        <div>
          <h3 className="text-3xl lg:text-4xl px-12 lg:px-48 text-center text-white uppercase">
            Wake the fuck up $PEPEX, time to wear your latex! 


          </h3>
        </div>
        <div className="flex flex-col space-y-16 lg:flex-row justify-between items-center px-8">
          <MovingImg
            customClassWrapper={"relative"}
            tz={3}
            tx={-10}
            ty={10}
            customClassImg={"w-full rounded-xl h-90 "}
            imgPath={"./block2/pl9.png"}
          />
          <MovingImg
            customClassWrapper={"relative"}
            tz={3}
            tx={10}
            ty={10}
            customClassImg={"w-full rounded-xl h-90 rotate-12"}
            imgPath={"./block2/pl1.png"}
          />
        </div>
      </div>
    </div>
  );
};
